<template>
  <v-form>
    <v-card-title class="modal-cadastro-titulo">
      Dados Bancário - {{ item.jsonData.fullName }}
    </v-card-title>

    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
          <!-- <v-text-field v-model="item.banco" label="Descrição:" :error-messages="bancoError" /> -->
          <v-select
            :items="listBanks"
            label="Banco"
            v-model="item.jsonData.banco"/>
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
          <v-text-field v-model="item.jsonData.agencia" label="Agencia:"  v-mask="['####']" />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
          <v-text-field v-model="item.jsonData.conta" label="Conta:"  v-mask="['#########']" />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
          <v-text-field v-model="item.jsonData.digit" label="Digito:"  v-mask="['##']" />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
          <v-select
            :items="listTypeConta"
            label="Banco"
            v-model="item.jsonData.tipoConta" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="modal-cadastro-footer">
      <!-- <v-btn :color="variables.colorPrimary" @click="limparForm" class="br-btn br-btn-cancelar">Limpar</v-btn> -->
      <v-btn type="submit" :color="variables.colorPrimary"  @click.prevent="submit" class="br-btn" :loading="loading" v-if="permiteAcao({ path: '/consultas/usuarios-web' }, 'edit')">Salvar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import { formatPhone } from '@/core/service/utils'

import validate from './validate'

export default {
  name: 'UsuarioWebDadosPessoais',
  mixins: [validationMixin],
  directives: {
    mask
  },
  data: () => ({
    loading: false,
    image: [],
    listTypeConta: [
      { text: 'Corrente', value: 'CC' },
      { text: 'Poupança', value: 'PP' }
    ],
    listBanks: [
      { text: 'Caixa', value: 'Caixa' },
      { text: 'Bando do Brasil', value: 'BB' }
    ]
  }),

  watch: {
    'image' (val) {
      this.addImage(val)
    },
  },

  computed: {
    ...mapGetters('usuariosWeb', {
      item: 'item',
      totalItens: 'totalItens',
    }),
    ...mapGetters('roles', ['permiteAcao']),
    
    ...validate,
    
    variables: () => variables,
  },
  methods: {
    ...mapActions('usuariosWeb', ['getItens', 'editarItem', 'deletarItem', 'clearItens', 'getItem', 'limparItem', 'editSaveItem']),

    submit () {
      const self = this

      if (self.$v.$invalid) {
        self.$v.item.$touch()
        return false 
      }

      this.loading = true
    
      self.usuarioEditSave(this.item)
    },

    usuarioEditSave (val) {
      const self = this
      val.jsonData.phone = formatPhone(val.jsonData.phone)
      val.jsonData.cpf = val.jsonData.cpf.replace(/\D/g, '')
      val.jsonData.cod = val.jsonData.cpf.replace(/\D/g, '')
      val.jsonData.ddd = val.jsonData.phone.replace(/\D/g, '').substring(0, 2),
      delete val.passwordConfirmation
      self.editSaveItem(val).then(() => {
        self.$emit('closeModal')
        self.loading = false
        self.getItens()
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'success',
          msg: 'Edição realizado com sucesso!'
        })
      }).catch(err => {
        self.loading = false
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'error',
          msg: err.error
        })
      })
    },

    // limparForm () {
    //   this.limparItem()
    // }
  },

  validations () {
    return {
      item: {
        // description: { required },
        // documents: { required }
      }
    }
  }
}
</script>
